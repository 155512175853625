import React from "react"
import img from "../images/team-home.png"

export default function ImageLeftSection() {
  let reversed = false;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }
  return (
    <div className="relative py-16 overflow-x-hidden  bg-gray-50 sm:py-6" id="about-us">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div
            className={classNames(
              reversed ? "order-2" : "",
              "relative sm:py-16 lg:py-0"
            )}
          >
            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-10">
              {/* Testimonial card*/}
              <div className="relative pb-10 overflow-hidden pt-[55.75%]">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={img}
                    alt="Our-Team"
                  />

              </div>
            </div>
          </div>

          <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-green-400">
                  The Forex Analytix team
                </h2>
                <p className="mt-4 text-lg text-gray-900">
                <b>The Forex Analytix team can assist you in achieving your goals as a trader.</b>
                <br/>
                <br/>
                Spend <span className="text-green-400 font-bold">one on one time with a mentor of your choice</span>, where the mentor (or “coach”) can spend time with you one on one, working through your trading process, risk management skills, or even getting you up to speed on various financial markets, including stocks, bonds, commodities, forex (foreign exchange), and more.
                </p>

            </div>

          </div>
        </div>
      </div>
    )
}
