import React, { useState, useEffect } from "react"
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import axios from 'axios';

/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const ShowSuccess = () => {
  return (
    <div className=" bg-green-50 p-4 my-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h5 className="text-sm font-medium text-black">Thank you for signing up to our newsletter!</h5>
        </div>
      </div>
    </div>
  )
}

const ShowError = () => {
  return (
    <div className="bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">There was a problem processing your request. Please try again later.</h3>
        </div>
      </div>
    </div>
  )
}
export default function NewsLetter() {
  const [state, setState] = useState(false);

  useEffect(() => {

  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined") {
      let name = document.getElementById("form1").elements["name"].value;
      let email = document.getElementById("form1").elements["email"].value;

      let fullName = name.split(/\s+/);
      let firstName = fullName.slice(0, -1).join(' ');
      let lastName = fullName.pop();

      let formDataObject = {
        'newsletter_subscription': {
            'email': email,
            'first_name': firstName,
            'last_name': lastName,
        },
    };

    axios.post("https://app.forexanalytix.com/api/v1/newsletter_subscriptions", formDataObject).then(response =>
      {
        document.getElementById("form1").elements["name"].value = '';
        document.getElementById("form1").elements["email"].value = '';
        setState({...state, isSuccess: true, isError: false});
      }).catch(error => {
        console.log(error);
        setState({...state, isError: true, isSuccess: false});
      });

    }

  }
  return (
    <div>
    <div className="bg-green-400 mt-0">

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-auto">
          <h2 className="text-3xl font-extrabold tracking-tight text-black sm:text-4xl" id="newsletter-headline">
            Sign up for our newsletter
          </h2>
          <p className="mt-3 max-w-3xl text-md leading-6 text-gray-800">
            Forex Analytix weekly market analysis and more, on every Sunday.
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8 lg:w-2/3">
          <form id="form1" onSubmit={handleSubmit} className="sm:flex">
            <label htmlFor="name" className="sr-only">
              Full name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="Name"
              required
                className="mr-2 w-full px-5 py-3 border border-transparent placeholder-gray-600  focus:ring-offset-black focus:border-black  focus:ring-black sm:max-w-xs"
              placeholder="Enter your full name"
            />
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
                className="mt-2 sm:mt-0 w-full px-5 py-3 border border-transparent placeholder-gray-600  focus:ring-offset-black focus:border-black  focus:ring-black sm:max-w-xs"
              placeholder="Enter your email"
            />
            <div className="mt-3 sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                  className="rounded-full w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-black hover:text-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                Subscribe
              </button>
            </div>
          </form>
            {state.isSuccess ? <ShowSuccess /> : ""}
            {state.isError ? <ShowError /> : ""}
          <p className="mt-3 text-sm ">
              <i>We care about the protection of your data. Read our{' '}
              <a href="/privacy" className=" font-medium underline">
                Privacy Policy
              </a>
              </i>
          </p>
        </div>
      </div>
    </div>

    </div>
  )
}
