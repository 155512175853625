import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import blakeImg from "../images/blake.png"
import gregaImg from "../images/grega.png"
import steveImg from "../images/steve.png"
import koenImg from "../images/koen.png"
import ryanImg from "../images/ryan.png"
import leandroImg from "../images/leandro.png"
import andreImg from "../images/andre.png"

const twIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" className="inline-block w-4 h-4 mr-1 face-twitter">
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  )
}

const mentors = [
  { name: "Blake Morrow", img: blakeImg, profilePage: "/blake", profile_url: "/blake", title: "CEO and Technical Analysis Expert" },
  { name: "Grega Horvat", img: gregaImg, profilePage: "/grega", profile_url: "/grega", title: "Renowned expert in financial markets, specializing in Elliott Wave" },
  { name: "Steve Voulgaridis", img: steveImg, profilePage: "/steve", profile_url: "/steve", title: "COO and Macro, Candlesticks & Technical Analysis Expert" },
  { name: "Ryan Littlestone", img: ryanImg, profilePage: "/ryan", profile_url: "/ryan", title: "Independent trader. Market analyst at Forex Analytix and ForexFlow.Live" },
  { name: "André Cardoso", img: andreImg, profilePage: "/andre", profile_url: "/andre", title: "Global Market Analyst, Harmonics & Fibonacci Expert" },
  { name: "Leandro Andrada Conde", img: leandroImg, profilePage: "/leandro", profile_url: "/leandro", title: "Independent Professional FX Trader, strategies and techniques" },
  { name: "Koen Vanderschrick", img: koenImg, profilePage: "/koen", profile_url: "/koen", title: "FX cash and options, fundamentals expert" },
]
export default function OurTeam() {
  return (
        <div className="bg-gray-900 text-white" id="mentors">
          <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-8 sm:space-y-12">
              <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                <h2 className="text-green-400 text-3xl font-extrabold tracking-tight sm:text-4xl">Meet your mentor</h2>
              </div>

              <div
                role="list"
                className1="mx-auto grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3"
            className="flex flex-wrap flex-row lg1:m-12 justify-center"
              >
                {mentors && mentors.map((e, index) => {
                  return(
                    <div key={index} className="m-10" >
                      <div className="">

                        <img
                          className="mx-auto h-40 w-40 rounded-full lg:w-48 lg:h-48 cursor-pointer border-4 border-solid border-green-400"
                          src={e.img}
                          onClick={() => {
                            if (typeof window !== "undefined")
                              window.open(e.profile_url, "_self")
                          }}
                          alt={e.name}
                        />

                        <div className="mb-3 mt-4">
                          <div className="font-semibold text-xl text-green-400">
                            {e.name}
                          </div>
                          <div className="mt-2 w-64" style={{minHeight: '70px'}}>
                            {e.title}
                          </div>
                        </div>

                        <div className="mt-6">
                          <button className="rounded-full bg-green-400 text-white font-semibold hover:text-black py-2 px-4 mr-3"
                            onClick={() => {
                              if (typeof window !== "undefined")
                                window.open(e.profilePage + "/#booknow", "_self")
                                // window.open(e.book_url, "_self")
                            }}
                          >
                            Book Now
                          </button>
                          <button className="rounded-full bg-white text-black font-semibold hover:text-green-400 py-2 px-4"
                            onClick={() => {
                              if (typeof window !== "undefined")
                                window.open(e.profilePage, "_self")
                            }}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
  )
}
