import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import OurTeam from "../components/our_team_section"
import CardsSection from "../components/cards-section"
import Testimonials from "../components/testimonials"
import ImageLeftSection from "../components/image_left_section"
import NewsLetter from "../components/newsletter"

import Seo from "../components/seo"
import GDPRConsent from "../components/gdpr-consent"


const IndexPage = () => {
  //
  // return (
  //   <>
  //   <html>
  //     <body>
  //       <Seo title='Trader Coach' />
  //       <GDPRConsent />
  //       <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
  //         <h1 className="text-5xl text-white font-bold mb-8 animate-pulse">
  //           <a href="https://trader-coach.com">
  //               Click <u>here</u> to perform any tests on trader-coach.com
  //           </a>

  //         </h1>
  //         {/* <p className="text-white text-lg mb-8">
  //           We're working hard to bring you something amazing. Stay tuned!
  //         </p> */}
  //       </div>
  //     </body>
  //   </html>
  //   </>
  // )
  return (
    <>
      <Seo title='Trader Coach' />
      <GDPRConsent />
      <Header />
      <main>
        <ImageLeftSection />
        <OurTeam />
        <CardsSection />
        <NewsLetter />
        <Testimonials />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
