import React from "react"

export default function Testimonials() {

  const testimonials = [
    {text: "This is the best macro commentary anywhere", name: "– Thomas S."},
    {text: "I really appreciate you all! Forex Analytix is the best kept secret in trading!", name: " – Skip A."},
    {text: "You guys have been great, very profitable this week everything in the green", name: " – Daniel B."},
    {text: "This group has made me a much better trader. I’ve just got to refine my edge with the new knowledge", name: " – Kevin O."},
    {text: "No doubt you are the best team, with such humbleness and willingness to teach and share", name: " – Laura G."},
    {text: "Thank you for your analysis and foresight into the markets. It has helped my trading quite a bit and it’s kept me focused on the bigger picture", name: " – Clyde K."},
    {text: "Learning the technicals etc. by hearing why folks do things and the behind - the - scenes action is priceless", name: " – Rick P."},
    {text: "This community has saved my career", name: " – Richard A."},
    {text: "I came here via a YouTube video search, joined the community and stayed here. This whole team is great.", name: " – Alexandre J."},
  ];

  return (
    <div className="bg-gray-50" id='testimonials'>
      <section className="max-w-7xl mx-auto py-16 px-4 sm:py-18 sm:px-6 lg:px-8 mb-0 text-gray-700">
          <div className="mb-16 text-center md:max-w-xl lg:max-w-3xl mx-auto">
            <h3 className="text-3xl font-bold mb-6 text-gray-900">What our clients say about us</h3>
            {/*<p className="mb-6 pb-2 md:mb-12 md:pb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam
              iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
              porro a pariatur veniam.
            </p>
            */}
          </div>
        <div className="grid md:grid-cols-3 gap-6 lg:gap-8 text-left">
        {testimonials.map((item,index)=>{
          return (

              <div className="mb-0 md:mb-0">
                <p className="mb-4">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="quote-left"
                    className="w-6 pr-2 inline-block text-green-400"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                    ></path>
                  </svg>
                {item.text} <span className="whitespace-nowrap italic">{item.name}</span>
                </p>
              </div>
          )
        })}
        </div>




      </section>
    </div>
  )
}
