import React from "react"

function Features({ data, landingPage }) {

  let items = [
    { title: "Pre Coaching customized guidance:", text: "Exchange an email with your mentor of choice what you are looking to achieve in your session so you have an expectation of what guidance you will receive." },
    { title: "Mentorship:", text: "Once you have completed your session, you may choose to continue your education process with your coach and will have the opportunity to schedule follow up sessions following your initial session. Being coached over time could be an ongoing process for most traders." },
    { title: "Developing Strategies:", text: "A big part of coaching for many traders is working with your mentor as he/she may assist you in developing a strategy that may work best for you depending on your timeframe, experience and risk tolerance as a trader" },
    { title: "Risk Management Process:", text: "In our experience, most traders struggle with “staying in losing trades too long” and “taking profits too early.” Your mentor may be able to help you unlearn poor risk management strategies and apply ones that may be better suited to your individual trading style." },
    {
      title: "Psychological Mentorship:", text: "Traders can struggle with the emotional aspect of trading. Your coach will give you guidance, and lay out a plan for learning, developing discipline and having a long-term perspective. We want you to succeed!" },
    { title: "Register now", text: " and take the first exciting step forward in your trading journey. Your learning path lies ahead and we can’t wait to help you move forward!" },
  ];

  return (
    <div className="relative py-16 bg-gray-50 sm:py-24 lg:py-16" id="how-it-works">
      <div className="max-w-md px-4 mx-auto text-left sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <>
          <p className="mt-2 text-3xl text-center font-extrabold tracking-tight text-black sm:text-4xl">
            Personal coaching with the Forex Analytix team
          </p>
        </>

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {items.map((item, index) => (
              <div key={index} className="pt-6">
                <div className={"flow-root px-6 pb-8 rounded-lg " + (index % 2 == 0 ? "bg-gray-200 text-black" : "bg-gray-900 text-gray-100")}>
                  <div className="h-72 min-h-full">
                    <div className={"mt-6 text-7xl mb-6 font-bold " + (index % 2 == 0 ? "text-gray-300" : "text-gray-700")}>
                      0{(index+1).toString()}
                    </div>
                    <p className=" text-base"><b>{item?.title} </b>{item?.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
