import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "../images/logo-header-black.png"
import { Helmet } from "react-helmet"
import { ChevronDownIcon } from '@heroicons/react/solid'

const navigation = [
  { name: 'ABOUT US', href: '/#about-us' },
  { name: 'MENTORS', href: '/#mentors' },
  { name: 'TESTIMONIALS', href: '/#testimonials' },
  { name: 'FOREX ANALYTIX', href: 'https://www.forexanalytix.com/' },
];

const mentors = [
  { name: 'Blake Morrow', href: '/blake' },
  { name: 'Grega Horvat', href: '/grega' },
  { name: 'Steve Voulgaridis', href: '/steve' },
  { name: 'Ryan Littlestone', href: '/ryan' },
  { name: 'André Cardoso', href: '/andre' },
  { name: 'Leandro Andrada Conde', href: '/leandro' },
  { name: 'Koen Vanderschrick', href: '/koen' },
]

export default function Header() {
  return (
    <div className="relative bg-green-400">
      <Popover className="relative bg-gray-900 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/#top">
                <span className="sr-only">Trader Coach</span>
                <img
                  className="h-14 w-auto sm:h-14"
                  src={logo}
                  alt="Trader Coach Header"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-green-400 p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">

              <a href="#about-us" className="font-bold text-base font-medium text-white hover:text-green-400">
                ABOUT US
              </a>

              <Popover.Button className="inline-flex items-center gap-x-1 fonr-bold text-base font-medium leading-6 text-white hover:text-green-400">
                <span>MENTORS</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/2 z-10 mt-10 flex w-screen max-w-min -translate-x-60 px-4">
                  <div className="w-56 shrink rounded-xl bg-gray-900 p-4 text-sm  leading-6 text-white shadow-lg ring-1 ring-gray-900/5">
                    {mentors.map((item,k) => (
                      <a index={k} href={item.href} className="block p-2 hover:text-green-400">
                        {item.name}
                      </a>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>

              <a href="#testimonials" className="font-bold text-base font-medium text-white hover:text-green-400">
                TESTIMONIALS
              </a>
              <a href="https://www.forexanalytix.com/" target="_new" className="font-bold text-base font-medium text-white hover:text-green-400">
                FOREX ANALYTIX
              </a>

            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="#mentors"
                className="rounded-full ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-bold text-white bg-green-400 hover:text-black"
              >
                Book Now
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-14 w-auto"
                      src={logo}
                      alt="Trader Coach Logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="text-base font-bold text-gray-900 hover:text-green-400">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  <a
                    href="/#mentors"
                    className="rounded-full w-full flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-gray-900 hover:text-green-400"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="text-black mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
                <span className="block inline">The Forex Analytix team</span>{' '}
                <span className="block inline">is ready to mentor you</span>{' '}
                <span className="block inline">one on one!</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-white sm:mt-5 font-extrabold text-2xl sm:text-4xl lg:text-4xl xl:text-4xl">
              Find your coach and
              <br/>start today
            </p>

            <div className="mt-10 mb-10 sm:flex sm:justify-center lg:justify-start">
              <div className="">
                <a
                  href="/#mentors"
                  className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                >
                  Book Now
                </a>
              </div>
            </div>

          </div>
          <div className="bg-green-400 pb-20 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full shadow-lg lg:max-w-xl">

                <span className="sr-only">Watch our video to learn more</span>
                <div className="relative text-center aspect-w-16 aspect-h-9">
                  <iframe
                    title="video"
                    className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/VS0z1j4Y_P0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

            </div>
          </div>
        </div>
      </main>

    </div>
  )
}
